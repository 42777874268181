// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body{
  background-color: #CAF0F8;
  

}


/* .cl{
  position: relative;
  z-index: -1;
} */

.bodyLogo{
  margin-top: 150px; /* Top margin */
  margin-right: 10px; /* Right margin */
  margin-bottom: 20px; /* Bottom margin */
  margin-left: 250px; 
  z-index: -1;
  /* position: relative; */
  
}

.bottomLogo{
  margin-bottom: 20px; /* Bottom margin */
}

.sideLogo{
 
  margin-bottom: 100px;
  margin-left: 0;
  z-index: -1;
}`, "",{"version":3,"sources":["webpack://./src/styles/App.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;;;AAG3B;;;AAGA;;;GAGG;;AAEH;EACE,iBAAiB,EAAE,eAAe;EAClC,kBAAkB,EAAE,iBAAiB;EACrC,mBAAmB,EAAE,kBAAkB;EACvC,kBAAkB;EAClB,WAAW;EACX,wBAAwB;;AAE1B;;AAEA;EACE,mBAAmB,EAAE,kBAAkB;AACzC;;AAEA;;EAEE,oBAAoB;EACpB,cAAc;EACd,WAAW;AACb","sourcesContent":["body{\n  background-color: #CAF0F8;\n  \n\n}\n\n\n/* .cl{\n  position: relative;\n  z-index: -1;\n} */\n\n.bodyLogo{\n  margin-top: 150px; /* Top margin */\n  margin-right: 10px; /* Right margin */\n  margin-bottom: 20px; /* Bottom margin */\n  margin-left: 250px; \n  z-index: -1;\n  /* position: relative; */\n  \n}\n\n.bottomLogo{\n  margin-bottom: 20px; /* Bottom margin */\n}\n\n.sideLogo{\n \n  margin-bottom: 100px;\n  margin-left: 0;\n  z-index: -1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
