// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* body{
    position: absolute;
    z-index: 1;
} */

.nav{
    /* background-color: #5ccae2; */
    height: 80px;
    width: 100%;
    position: relative;
    top: 0px;
    z-index: 2;
}
nav ul{
    /* position: absolute; */
    float: right;
    margin-right: 20px;
}

nav ul li{
    display: inline-block;
    line-height: 80px;
    margin: 0 5px;
    text-decoration: none;

}



.a{
    font-size : 17px;
    width: 194px;
height: 58px;
flex-shrink: 0;
    padding: 10px 13px;
    text-decoration: none;
    background-color: #48CAE4;
    color: white;
    border: 2px solid #023E8A;
    border-radius: 20px;

}
.a.active, .a:hover {
    /* background: #0d6d83;
    transition: .5s;
    text-decoration: none; */
    /* pointer: cursor */

}

.logo{
     padding: 10px 10px;
}

.b{
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/navbar/index.css"],"names":[],"mappings":"AAAA;;;GAGG;;AAEH;IACI,+BAA+B;IAC/B,YAAY;IACZ,WAAW;IACX,kBAAkB;IAClB,QAAQ;IACR,UAAU;AACd;AACA;IACI,wBAAwB;IACxB,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,qBAAqB;IACrB,iBAAiB;IACjB,aAAa;IACb,qBAAqB;;AAEzB;;;;AAIA;IACI,gBAAgB;IAChB,YAAY;AAChB,YAAY;AACZ,cAAc;IACV,kBAAkB;IAClB,qBAAqB;IACrB,yBAAyB;IACzB,YAAY;IACZ,yBAAyB;IACzB,mBAAmB;;AAEvB;AACA;IACI;;4BAEwB;IACxB,oBAAoB;;AAExB;;AAEA;KACK,kBAAkB;AACvB;;AAEA;IACI,eAAe;AACnB","sourcesContent":["/* body{\n    position: absolute;\n    z-index: 1;\n} */\n\n.nav{\n    /* background-color: #5ccae2; */\n    height: 80px;\n    width: 100%;\n    position: relative;\n    top: 0px;\n    z-index: 2;\n}\nnav ul{\n    /* position: absolute; */\n    float: right;\n    margin-right: 20px;\n}\n\nnav ul li{\n    display: inline-block;\n    line-height: 80px;\n    margin: 0 5px;\n    text-decoration: none;\n\n}\n\n\n\n.a{\n    font-size : 17px;\n    width: 194px;\nheight: 58px;\nflex-shrink: 0;\n    padding: 10px 13px;\n    text-decoration: none;\n    background-color: #48CAE4;\n    color: white;\n    border: 2px solid #023E8A;\n    border-radius: 20px;\n\n}\n.a.active, .a:hover {\n    /* background: #0d6d83;\n    transition: .5s;\n    text-decoration: none; */\n    /* pointer: cursor */\n\n}\n\n.logo{\n     padding: 10px 10px;\n}\n\n.b{\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
