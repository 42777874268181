// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  position: absolute;
  z-index: 1;
}
.login {
  background-color: white;
  position: absolute;

  height: 600px;
  width: 500px;
  margin-top: 200px;
  margin-right: 100px;
  /* margin-bottom: 15px;  */
  margin-left: 1000px;
  border: 2px solid rgb(11, 159, 162);
  border-radius: 20px;
  box-shadow: 5px 5px 10px rgb(11, 159, 162);
}

.loginBox {
  padding: 20px;
  padding-top: 30px;
}
.h1 {
  color: #023e8a;

  font-family: "Josefin Sans";
  font-size: 31.141px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.inputDiv {
  padding-right: 50px;
  margin-left: 50px;
  padding: 10px;
}

input {
  border: none;
  border-bottom: 2px solid blue;
  width: 80%;
  font-size: 20px;
  outline: none;
}

.bt {
    width: 30%;
  margin-left: 50px;
  margin-top: 20px;
  border-radius: 10px;
  background: #00b4d8;
  font-size: medium;
  color: white;
  cursor: pointer;
  font-weight: 400;
  padding: 10px;
}

.i{
    /* color: white; */
    height: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/Login/index.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,UAAU;AACZ;AACA;EACE,uBAAuB;EACvB,kBAAkB;;EAElB,aAAa;EACb,YAAY;EACZ,iBAAiB;EACjB,mBAAmB;EACnB,0BAA0B;EAC1B,mBAAmB;EACnB,mCAAmC;EACnC,mBAAmB;EACnB,0CAA0C;AAC5C;;AAEA;EACE,aAAa;EACb,iBAAiB;AACnB;AACA;EACE,cAAc;;EAEd,2BAA2B;EAC3B,mBAAmB;EACnB,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;EACnB,iBAAiB;EACjB,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,6BAA6B;EAC7B,UAAU;EACV,eAAe;EACf,aAAa;AACf;;AAEA;IACI,UAAU;EACZ,iBAAiB;EACjB,gBAAgB;EAChB,mBAAmB;EACnB,mBAAmB;EACnB,iBAAiB;EACjB,YAAY;EACZ,eAAe;EACf,gBAAgB;EAChB,aAAa;AACf;;AAEA;IACI,kBAAkB;IAClB,YAAY;AAChB","sourcesContent":["body {\n  position: absolute;\n  z-index: 1;\n}\n.login {\n  background-color: white;\n  position: absolute;\n\n  height: 600px;\n  width: 500px;\n  margin-top: 200px;\n  margin-right: 100px;\n  /* margin-bottom: 15px;  */\n  margin-left: 1000px;\n  border: 2px solid rgb(11, 159, 162);\n  border-radius: 20px;\n  box-shadow: 5px 5px 10px rgb(11, 159, 162);\n}\n\n.loginBox {\n  padding: 20px;\n  padding-top: 30px;\n}\n.h1 {\n  color: #023e8a;\n\n  font-family: \"Josefin Sans\";\n  font-size: 31.141px;\n  font-style: normal;\n  font-weight: 400;\n  line-height: normal;\n}\n\n.inputDiv {\n  padding-right: 50px;\n  margin-left: 50px;\n  padding: 10px;\n}\n\ninput {\n  border: none;\n  border-bottom: 2px solid blue;\n  width: 80%;\n  font-size: 20px;\n  outline: none;\n}\n\n.bt {\n    width: 30%;\n  margin-left: 50px;\n  margin-top: 20px;\n  border-radius: 10px;\n  background: #00b4d8;\n  font-size: medium;\n  color: white;\n  cursor: pointer;\n  font-weight: 400;\n  padding: 10px;\n}\n\n.i{\n    /* color: white; */\n    height: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
